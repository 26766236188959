@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply font-sans antialiased bg-background text-foreground;
  }
  ol {
    @apply list-decimal list-inside pl-4;
  }
  ul {
    @apply list-disc list-inside pl-4;
  }
}


h1, h2, h3, h4, h5, h6 {
  @apply font-semibold
}

h1 {
  @apply text-3xl text-center pb-8 uppercase;
}

h2 {
  @apply text-3xl uppercase
}

h3 {
  @apply text-2xl
}

h4 {
  @apply text-xl
}
h5 {
  @apply text-lg
}
h6 {
  @apply text-base
}

a {
  @apply text-theme hover:text-blue-800 ;
}

a.link {
  @apply text-theme hover:text-blue-800 ;
}

.nav-container a {
  @apply text-white;
}

.nav-container {
  @apply flex justify-end md:justify-between items-center px-2 py-2 bg-theme text-white border-b border-neutral-300;
}
.nav-menu {
  @apply md:block md:flex gap-4;
}

.nav-menu-item {
  @apply flex justify-end items-center gap-1 px-4 md:px-0 md:py-3 hover:underline hover:opacity-90;
}

.nav-menu-item svg {
  @apply md:hidden;
}

.burger {
  @apply cursor-pointer px-4 py-1 md:hidden;
}

.logo {
  @apply hover:opacity-90 mr-auto md:mr-0;
}

.card-grid {
  @apply grid grid-cols-2 mx-4 sm:grid-cols-3 lg:grid-cols-4 lg:mx-48;
}

.card {
  @apply list-none bg-white rounded overflow-hidden shadow-md;
}

.events {
  @apply grid gap-2 grid-cols-1 sm:mx-4 lg:mx-48;
}

.event-card{
  @apply text-black flex place-items-center list-none bg-white rounded overflow-hidden shadow-md;
}

.news {
  @apply grid gap-4 grid-cols-2 mx-4 sm:grid-cols-3 lg:grid-cols-4 lg:mx-48;
}

.news-card {
  @apply h-full bg-white rounded overflow-hidden shadow-md ;
}

.news-card img {
  @apply object-cover;
}

.home {
  @apply bg-theme text-white;
}

.home h2 {
  @apply text-center text-3xl font-bold p-2 mb-8 border max-w-2xl mx-auto rounded-md;
}

.home .timeline {
  @apply hidden 
}

.timeline {
  @apply bg-gray-300 flex flex-wrap justify-center items-center mb-10;
}

.timeline .active {
  @apply bg-white;
}

.timeline-date {
  @apply hover:bg-white text-center cursor-pointer px-3 py-2 md:px-6 md:py-4 list-none /*transition ease-out duration-200*/;
}

.btn {
  @apply cursor-pointer bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-blue-700 rounded leading-loose;
}

.home .btn {
  @apply bg-theme text-white;
}

.player {
  @apply flex bg-white w-full sticky bottom-0 left-0 border-t shadow-teal-500 ; /*h-[80px]*/
}

.error {
  @apply /* bg-red-300 text-red-600 p-8 */ bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  @apply items-center gap-4 sm:gap-8;
}

.rhap_additional-controls {
  @apply hidden;
}

footer .social-icons a {
  @apply text-white
}